export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return '£';
  }
};

export function createArrayDeepCopy<T>(data: Array<T>): Array<T> {
  return data.map((item) => ({ ...item }));
}

export const availableAutofillDomain = [
  'lever.co',
  'workable.com',
  'greenhouse.io',
  'teamtailor.com',
  'bamboohr.com',
  'workday.com',
  'myworkdayjobs.com',
  'jobvite.com',
  'smartrecruiters.com',
  'recruitee.com',
];

export function isDataAvailable(data?: string | string[]): boolean {
  return Array.isArray(data)
    ? data.filter((item) => !!item?.trim()).length > 0
    : !!data?.trim();
}

export function sendTokenToExtension(email: string, token: string): void {
  if (typeof chrome !== 'undefined' && chrome.runtime) {
    chrome.runtime.sendMessage(
      process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID || '',
      { action: 'storeEmailAndToken', email, token },
      (response) => {
        if (response?.success) {
          window.close();
        } else {
          console.error(
            'Failed to store token in the extension',
            response?.error
          );
          location.reload();
        }
      }
    );
  } else {
    console.error('Chrome runtime is not available');
  }
}

export function logoutExtension(): void {
  if (typeof chrome !== 'undefined' && chrome.runtime) {
    chrome.runtime.sendMessage(
      process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID || '',
      { action: 'removeEmailAndToken' },
      (response) => {
        if (!response?.success) {
          console.error(
            'Failed to logout token in the extension',
            response?.error
          );
          location.reload();
        }
      }
    );
  } else {
    console.error('Chrome runtime is not available');
  }
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
